<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
        <Carousel :items="[{image: mediaInfo.img}]" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
				<p>{{content.desc}}</p>
                <p v-if="content.isScheduling">
                    Congratulations! Your event soft holds have been placed for {{ content.startDate }} - {{ content.endDate }}. We look forward to seeing your tour on {{ content.startDate }}. We've sent you can email with all the details. If you need to reschedule the tour, please click the reschedule button in the email or login to your account.
                    <br/><br/>You have the 3rd hold on this date. After the tour, if you wish to proceed with booking. we will challenge the previous date holders. They will have 48 hours to either book or release the dates. Good luck!
                </p>
                <p v-else>
                    Congratulations! Your event reservations have been made for {{content.startDate}} - {{content.endDate}}. One of our event specialists will reach out to you.
                </p>
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import SingleBlog from '../../organisms/SingleBlog/SingleBlog.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, SingleBlog, Carousel, CopyRight
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            footerProps
        }
    },
}
</script>

<style>

</style>